.text-area-container {
  position: relative;
  width: 100%;
  border: 1px solid #bfbfbf;
  background-color: #fff;
  padding: 2px;
  cursor: pointer;
}
.text-area-container.editing,
.text-area-container.readOnly {
  cursor: initial;
}
.text-area-container textarea {
  width: calc(100% - 6px);
}
.text-area-container .buttons {
  position: relative !important;
  width: 100%;
  margin-top: 5px;
}
.text-area-container .buttons button {
  border: 0;
  border-right: 2px solid #fff;
  width: 50%;
}
.text-area-container .buttons button:last-child {
  border-right: 0;
}
