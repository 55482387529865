.options-select-container {
  display: inline-flex;
  flex-flow: column nowrap;
  box-sizing: border-box;
  height: 35px;
  overflow: visible;
  position: relative;
  cursor: pointer;
  text-align: left;
}
.options-select-container.disabled {
  cursor: initial;
}
.options-select-container.disabled .select-input {
  background-color: BUTTON_BG_DISABLED;
}
.options-select-container.expanded .select-input {
  border-bottom: none;
}
.options-select-container .select-input {
  display: flex;
  flex: 0 0 auto;
  user-select: none;
  border: 1px solid #bfbfbf;
  outline: none;
  line-height: 26px;
}
.options-select-container .select-input .select-sign,
.options-select-container .select-input .text-box {
  padding: 5px;
}
.options-select-container .select-input .select-sign {
  font-size: 13px;
  width: 23px;
}
.options-select-container .select-input .select-sign:before {
  content: "▼";
}
.options-select-container .select-input .select-sign.right {
  text-align: right;
}
.options-select-container .select-input .select-sign:left {
  text-align: left;
}
.options-select-container .select-input .select-sign.full-triangle {
  position: relative;
  text-align: center;
}
.options-select-container .select-input .select-sign.full-triangle svg {
  position: absolute;
  width: 23px;
  height: 13px;
  top: 11.5px;
}
.options-select-container .select-input .select-sign.full-triangle.right svg {
  right: 0;
}
.options-select-container .select-input .select-sign.full-triangle.left svg {
  left: 0;
}
.options-select-container .select-input .select-sign.full-triangle:before {
  content: '';
}
.options-select-container .select-input .text-box {
  flex-grow: 1;
}
.options-select-container .options-container {
  flex: 0 0 auto;
  margin: 0;
  padding: 0;
  border: 1px solid #bfbfbf;
  border-top: none;
  background-color: #fff;
  visibility: hidden;
  z-index: 111;
}
.options-select-container .options-container.with-numbered-options {
  counter-reset: options;
}
.options-select-container .options-container.with-numbered-options .option {
  counter-increment: options;
}
.options-select-container .options-container.with-numbered-options .option:before {
  display: inline-block;
  content: counter(options) '.';
  margin-right: 10px;
}
.options-select-container .options-container.visible {
  visibility: visible;
}
.options-select-container .options-container .option {
  cursor: pointer;
  white-space: nowrap;
}
.options-select-container .options-container .option:hover {
  background: BUTTON_BACKGROUND;
}
.options-select-container .options-container .option.selected {
  background: BUTTON_BACKGROUND;
}
.options-select-container .options-container .option[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}
.options-select-container .options-container .options-group {
  cursor: default;
}
.options-select-container .options-container .options-group .options-group__caption {
  font-weight: bold;
  padding-left: 5px;
}
.options-select-container .options-container .options-group .options-group__options ul {
  padding: 0;
  list-style: none;
  margin: 10px 0;
}
.options-select-container ul {
  list-style: none;
  padding: 0;
}
.options-select-container ul .option {
  padding: 0 22px 0 5px;
  line-height: 26px;
  height: 26px;
}
