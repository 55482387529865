.ep-guideline-link-authors {
  font-weight: bold;
  font-size: 14px;
  color: #000;
}
.ep-guideline-link-profile a {
  font-size: 15px;
  line-height: 21px;
  letter-spacing: 0.36px;
  color: #b300cb;
  text-decoration: none;
  font-weight: bold;
}
.ep-guideline-link-guideline a {
  font-size: 14px;
  line-height: 21px;
  color: #000;
  text-decoration: none;
}
