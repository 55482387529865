.btn-icon-container {
  cursor: pointer;
}
.btn-icon-container .label-text {
  font-size: 10px;
}
.btn-icon-container:hover {
  color: #5b666e;
  background-color: transparent;
}
.btn-icon-container:hover .btn-icon {
  color: #5b666e;
}
.btn-icon-container.disabled {
  cursor: initial;
  color: #8e99a1;
}
.btn-icon-container .btn-icon {
  padding: 0;
  background-color: transparent;
  color: #2d3236;
  line-height: 20px;
}
.btn-icon-container .btn-icon.disabled {
  cursor: initial;
  color: #8e99a1;
}
.btn-icon-container .btn-icon.disabled:hover {
  color: #8e99a1;
}
